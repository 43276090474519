$(document).ready ->
   if $('#broadcast-message-counter').length > 0
        $('#broadcast-message-counter').html($('#broadcast-message').val().length + ' chars, max 3,000')
        $('#broadcast-message').keyup ( ->
            $('#broadcast-message-counter').html($('#broadcast-message').val().length + ' chars, max 3,000')
        )
    if $('#broadcast-title-counter').length > 0
        $('#broadcast-title-counter').html($('#broadcast-title').val().length + ' chars, max 40')
        $('#broadcast-title').keyup ( ->
            $('#broadcast-title-counter').html($('#broadcast-title').val().length + ' chars, max 40')
        )